import React, {Component } from 'react'
import PropTypes from 'prop-types'

import ReactJWPlayer from '../../react-jw-player/react-jw-player'
import PlayerMeta from './PlayerMeta'
import SvgIcon from '../SvgIcon'
import classnames from 'classnames'

import playIcon from '../../../images/icons/24/play.svg'
import pauseIcon from '../../../images/icons/24/pause.svg'
import nextIcon from '../../../images/icons/16/next.svg'
import prevIcon from '../../../images/icons/16/prev.svg'
import LoadingIndicator from '../LoadingIndicator'
import {JW_PLAYER_SRC, stripFormatting} from '../../util'

let getPlayerItem = (queueItem) => {
  const tracks = queueItem.vtt ? [{
        file: queueItem.vtt,
        kind: 'chapters'
  }] : []


  let imagesBySize = (
      queueItem.performance.preferred_image ||
      queueItem.composition.preferred_image ||
      { instances: [] }
    ).instances
    .concat()
    .sort((a,b) => b.width - a.width);
  let largestImage = imagesBySize[0]
  return [{
    file: queueItem.url,
    _gaTitle: `${queueItem.composition.alternative_formatted_title} / ${queueItem.composition.composer.first_name} ${queueItem.composition.composer.last_name}`,
    _gaId: `R-${queueItem.recording_uuid}`,
    _gaProgram: `${stripFormatting(queueItem.performance.program.title)} (${queueItem.performance.dateDisplay()})`,
    tracks: tracks,
    _spco_kind: 'player_item',
    _spco_meta: {
      title: queueItem.composition.alternative_formatted_title,
      composer_name: queueItem.composition.combined_composer_arranger_credit,
      conductor_name: queueItem.performance.conductor && queueItem.performance.conductor.full_name,
      featured_artist_names: queueItem.performance.featuredartistSet.toModelArray().map(
        (featured_artist, index) =>
          [
            featured_artist.artist.first_name,
            featured_artist.artist.last_name
          ].filter((x) => x).join(" ")),  // handles single-name performers
      image: largestImage
    }
  }]
}

class Player extends Component {
  constructor() {
    super()

    this.onReady = this.onReady.bind(this)
    this.onFinished = this.onFinished.bind(this)
    this.onError = this.onError.bind(this)
    this.onPlayFailed = this.onPlayFailed.bind(this)
    this.onResume = this.onResume.bind(this)
    this.onPlayerLoaded = this.onPlayerLoaded.bind(this)
    this.resumeEvent = this.resumeEvent.bind(this)

    this.state = {
      playerInitialized: false,
      playerQueue: [],
      playerReady: false,
      currentQueueItem: null,
      ignoreNextPlay: false,
      playerLoaded: false,
      pendingLoadQueue: false
    }
  }

  onReady() {
    this.setState({
      playerReady: true
    })
    if(this.props.status === 'playing') {
      this.playerInstance().play()
    }

    if(process.env.REACT_APP_USE_NATIVE_PLAYER || process.env.REACT_APP_ANDROID) {
      // Hack to give native player access to next & previous, which otherwise
      // live in Redux land and are inaccessible from global scope
      window.spcoNextTrack = this.props.onNext;
      window.spcoPrevTrack = this.props.onPrev;
      window.spcoPause = this.props.onPause;
      window.spcoPlay = this.resumeEvent;
    }
  }

  onFinished() {
    this.props.onItemFinished()
  }

  onError(message) {
    alert("Sorry, we're having a problem playing the audio for this composition.")
    window.Rollbar.error("JWPlayer Media Error", {message: message, composition: this.state.currentQueueItem})
    this.props.onPause()
  }
  onPlayerLoaded() {
    this.setState({
      playerLoaded: true
    })

    if(this.state.pendingLoadQueue) {
      const nextQueueItem = this.nextQueueItem(this.props)
      this.playerInstance().load(getPlayerItem(nextQueueItem))
      if(this.props.status === 'playing') {
        this.playerInstance().play()
      }
      this.setState({
        pendingLoadQueue: false
      })
    }

  }
  onPlayFailed() {
    this.setState({
      ignoreNextPlay: true
    })
    this.props.onPause()
  }
  resumeEvent() {
    if(this.state.playerLoaded) {
      this.props.onResume()
    }
  }
  // Workaround to handle errant resume after pausing due to safari error
  onResume() {
    if(! this.state.ignoreNextPlay) {
      this.props.onPlayerPlay()
    }
    else {
      this.setState({
        ignoreNextPlay: false
      })
    }
  }
  playerInstance() {
    return window.jwplayer('spco-player')
  }

  playerState() {
    return this.playerInstance().getState()
  }

  nextQueueItem(nextProps) {
    const nextQueueItem = nextProps.fullQueue[nextProps.currentItem]
    this.setState({
      currentQueueItem: nextQueueItem
    })
    return nextQueueItem
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.fullQueue.length && ! this.state.playerInitialized) {
      const nextQueueItem = this.nextQueueItem(nextProps)
      this.setState({
        playerInitialized: true,
        playerQueue: getPlayerItem(nextQueueItem)
      })
    }
    else if((nextProps.status === 'playing' || this.props.status === 'playing') && nextProps.fullQueue.length === 0) {
      if(this.state.playerInitialized) { // handles borked queue
        this.playerInstance().stop()
      }
    }
    // only move on to next item if queue length didn't change
    else if(nextProps.currentItem !== this.props.currentItem
              && nextProps.fullQueue.length > 0) {
     // if queue length is the same or longer, we changed items.
     if(nextProps.fullQueue.length >= this.props.fullQueue.length) {
       const nextQueueItem = this.nextQueueItem(nextProps)
       if(this.state.playerLoaded) {
         this.playerInstance().load(getPlayerItem(nextQueueItem))
         if(nextProps.status === 'playing') {
           this.playerInstance().play()
         }
       }
       else { // player not loaded;
         // Jwplayer may not be available (clicked "play item" before
         // jwplayer is initialized); set the pending flag & load it later
         this.setState({
           pendingLoadQueue: true
         })
       }
     }
     // if queue length is shorter, we are either on the same item or we removed
     // the playing item from the queue; only need to handle pause
     else {
       if(nextProps.status === 'paused') {
         this.playerInstance().stop()
       }
     }
    }
    else if(nextProps.status === 'playing' && this.props.status === 'paused') {
      let state = this.playerState()
      if(state !== 'playing' && state !== 'buffering') {
        this.playerInstance().play()
      }
    }
    else if(nextProps.status === 'paused' && this.props.status === 'playing') {
      this.playerInstance().pause()
      // Do not let JWPlayer continuously replay last song in queue
      // on native apps — basically force a stop call
      if(process.env.REACT_APP_USE_NATIVE_PLAYER || process.env.REACT_APP_USE_NATIVE_PLAYER) {
        if(nextProps.finishedAt) {
          this.playerInstance().stop() // Clear queue
          this.props.onPlayerFinished() // remove finishedAt
        }
      }

      if(nextProps.fullQueue.length === 0) {
        this.playerInstance().stop()
      }
      else if(nextProps.fullQueue.length !== this.props.fullQueue.length) {
        const nextQueueItem = this.nextQueueItem(nextProps)
        this.playerInstance().load(getPlayerItem(nextQueueItem))
      }
    }
  }

  render() {
    if(! this.state.playerInitialized) {
      return <div></div>
    }

    const play = this.state.playerLoaded ? <SvgIcon className="play" icon={playIcon} alt="Play" height="24" width="24" fill="#ffffff" /> : <LoadingIndicator alt="A" />
    const pause = <SvgIcon className="pause" icon={pauseIcon} alt="Pause" height="24" width="24" fill="#ffffff" />
    const playToggle = this.props.status === 'paused' ? this.resumeEvent : this.props.onPause
    const playPause = this.props.status === 'paused' ? play : pause
    const next = <SvgIcon onClick={this.props.onNext} className="next" icon={nextIcon} fill="#d83b20" height="16" width="16" alt="Next track" />
    const prev = <SvgIcon onClick={this.props.onPrev} className="prev" icon={prevIcon} fill="#d83b20" height="16" width="16" alt="Previous" />

    const queueLength = this.props.fullQueue.length
    let controlClasses = classnames(
      {
        custom_controls: true,
        prev_enabled: queueLength > 1 && this.props.currentItem > 0,
        next_enabled: queueLength > 1 && this.props.currentItem < this.props.fullQueue.length - 1
      }
    )
    let playerClasses = classnames(
      {
        player_wrap: true,
        initialized: true,
        empty_queue: queueLength === 0
      }
    )

    // const title = `${this.state.currentQueueItem.composition.alternative_formatted_title}, ${this.state.currentQueueItem.composition.composer.full_name}`;
    return (
      <div className={playerClasses}>
        <div className={controlClasses}>
          <div className="prev">{prev}</div>
          <div className="playPause" onClick={playToggle}>{playPause}</div>
          <div className="next">{next}</div>
        </div>

        <ReactJWPlayer
          playerId="spco-player"
          isAutoPlay={false}
          height="65"
          width="100%"
          onReady={this.onReady}
          onError={this.onError}
          onPlay={this.props.onPlayerPlay}
          onPause={this.props.onPause}
          onResume={this.onResume}
          onOneHundredPercent={this.onFinished}
          gaCategory="Media"
          playerScript={JW_PLAYER_SRC}
          onPlayAttemptFailed={this.onPlayFailed}
          onPlayerLoaded={this.onPlayerLoaded}
          file=""
          playlist={this.state.playerQueue} />
        <PlayerMeta fullQueue={this.props.fullQueue} currentItem={this.props.currentItem} status={this.props.status} setQueueItem={this.props.setQueueItem} removeQueueItem={this.props.removeQueueItem} />
      </div>
    )
  }
}

Player.propTypes = {
  status: PropTypes.string,
  currentItem: PropTypes.number,
  fullQueue: PropTypes.array,
  onPause: PropTypes.func,
  onResume: PropTypes.func,
  onPlayerPlay: PropTypes.func,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  setQueueItem: PropTypes.func,
  removeQueueItem: PropTypes.func,
  onItemFinished: PropTypes.func,
  onPlayerFinished: PropTypes.func,
  finishedAt: PropTypes.number
}

export default Player